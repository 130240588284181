import React, {Component} from 'react';
import {GatsbyImage} from "gatsby-plugin-image"
import {Modal} from "react-bootstrap";

class HeroImageProduto extends Component {

    constructor(props) {
        super(props);
        this.handleModalVideo = this.handleModalVideo.bind(this);
        this.state = ({showModalVideo: false})
    }

    handleModalVideo() {
        this.setState(prevState => ({
            showModalVideo: !prevState.showModalVideo
        }));
    }

    render() {
        if (this.props.props.video.videoProduto !== null && this.props.props.video.videoProduto !== undefined) {
            return (
                <>
                    <GatsbyImage className="image card-img-top" objectFit="fill" style={{maxHeight: "600px"}}
                                image={this.props.props.poster.imagemProduto.gatsbyImageData}
                                alt={this.props.props.product.nomeProduto}/>
                    <div className="container mt-3 mb-3">
                        <div className="col-sm-4">
                            <input type="button" onClick={this.handleModalVideo} className="btn btn-primary"
                                value="Vídeo do Produto"/>
                        </div>
                    </div>
                    <Modal size="lg" show={this.state.showModalVideo} onHide={this.handleModalVideo}>
                        <Modal.Header closeButton>
                            <Modal.Title>Vídeo do Produto</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{overflowX: "scroll", msOverflowX: "scroll"}}>
                            <video className="video-iberica-product-modal" autoPlay loop
                                poster={this.props.props.poster.imagemProduto.file.url}>
                                <source src={this.props.props.video.videoProduto.file.url}
                                        type={this.props.props.video.videoProduto.file.contentType}/>
                            </video>
                        </Modal.Body>
                    </Modal>
                </>
            )
        } else {
            return(
                <>
                    <GatsbyImage className="image card-img-top" objectFit="fill" style={{maxHeight: "600px"}}
                                image={this.props.props.poster.imagemProduto.gatsbyImageData}
                                alt={this.props.props.product.nomeProduto}/>
                </>
            )
        }
    }

}

export default HeroImageProduto